import React from "react"

import Layout from "../components/layout"

import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="ARCHITEKTIK" />
    <div className="header">
      <h1>WWW.ARCHITEKTIK.COM</h1>
      <h2>office@architektik.com</h2>
    </div>
    {/* <div id="social">
      <img src="../images/twitter.svg" alt="twitter" />
      <img src="../images/facebook.svg" alt="facebook" />
      <img src="../images/instagram.svg" alt="instagram" />
    </div> */}
  </Layout>
)

export default IndexPage
